<template>
  <div
    class="background"
    justify="center"
    :class="{
      'lg-up': $vuetify.breakpoint.lgAndUp,
      md: $vuetify.breakpoint.md,
      sm: $vuetify.breakpoint.sm,
      xs: $vuetify.breakpoint.xs
    }"
  >
    <h1 class="views-h1">{{ spec.title }}</h1>

    <!-- <v-card flat tile :style="$styles()">
      <v-window v-model="selectedIndex">
        <v-window-item v-for="(item, index) in spec.childViews" :key="index">
          TEST1
        </v-window-item>
      </v-window>

      <v-card-actions class="justify-space-between">
        <v-btn text @click="prev">
          <v-icon>chevron_left</v-icon>
        </v-btn>
        <v-item-group v-model="selectedIndex" class="text-center" mandatory>
          <v-item v-for="(item, index) in spec.childViews" :key="index">
            <v-btn
              slot-scope="{ active, toggle }"
              :input-value="active"
              icon
              @click="toggle"
            >
              <v-icon size="8">lens</v-icon>
            </v-btn>
          </v-item>
        </v-item-group>
        <v-btn text @click="next">
          <v-icon>chevron_right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card> -->

    <v-row class="content mx-auto">
      <v-col cols="12" sm="1" md="1">
      </v-col>

      <v-col cols="12" sm="10" md="10">
        <glib-panels-responsive
          :spec="{
            width: 'matchParent',
            align: 'center',
            childViews: spec.childViews
          }"
        />
      </v-col>
    </v-row>

    <!-- <template v-for="(item, index) in spec.items" >
      <v-row
          v-if="index % 2 == 0"
        class="content mx-auto"
        :class="{
          'steps-container-md-up': $vuetify.breakpoint.mdAndUp,
          'steps-container-sm-down': $vuetify.breakpoint.smAndDown
        }"
        :key="index"
      >
        <v-col
          cols="12"
          sm="12"
          md="6"
          class="content-image-container"
          style="justify-content: center;"
        >
          <img
            :class="{
              'content-image-lg-up': $vuetify.breakpoint.lgAndUp,
              'content-image-md': $vuetify.breakpoint.mdOnly,
              'content-image-sm-down': $vuetify.breakpoint.smAndDown
            }"
            :src="
              item.imageUrl
            "
            alt="Customize App"
          />
        </v-col>
        <v-col align-self="center" cols="12" sm="12" md="6" class="steps-text">
          <p>
            What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry's standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book it has?
          </p>
        </v-col>

      </v-row>

      <v-row
        v-else
        class="content mx-auto"
        :class="{
          'steps-container-md-up': $vuetify.breakpoint.mdAndUp,
          'steps-container-sm-down': $vuetify.breakpoint.smAndDown
        }"
        :key="index"
      >
        <v-col align-self="center" cols="12" sm="12" md="6" class="steps-text">
          <p>
            What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry's standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book it has?
          </p>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="6"
          class="content-image-container"
          style="justify-content: center;"
        >
          <img
            :class="{
              'content-image-lg-up': $vuetify.breakpoint.lgAndUp,
              'content-image-md': $vuetify.breakpoint.mdOnly,
              'content-image-sm-down': $vuetify.breakpoint.smAndDown
            }"
            :src="
              item.imageUrl
            "
            alt="Customize App"
          />
        </v-col>
      </v-row>


    </template> -->

  </div>
</template>

<script>
export default {
  props: {
    spec: { type: Object, required: true },
  },
  data: () => ({}),
  methods: {
    $ready() {},
    cardVisible(item) {
      // if (item.title) {
      //   return true;
      // }
      // return this.$vuetify.breakpoint.mdAndUp;
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 60px;
  text-align: center;
  // font-size: 40px;
  // font-weight: 700;
  // color: #37325f;
}
.background {
  // background-color: #fafafa !important;
  background-color: #d2dbd8;
  border-top: 2px solid #ffffff;
}
.content {
  padding: 20px 0px;
}
.list-number {
  width: 50px;
  font-size: 40px;
  color: #37325f;
}
.list-title {
  padding: 14px 0px;
  font-size: 20px;
  color: #37325f;
}
.lg-up {
  .content {
    max-width: 1025px !important;
  }
}
.md {
  .content {
    max-width: 900px !important;
  }
}
.sm {
  .content {
    max-width: 90vw !important;
  }
}
.xs {
  .content {
    width: 100vw !important;
  }

  p {
    max-width: 360px;
  }
}
.card-background {
  padding-top: 8px;
  background-color: #fafafa !important;
}
p {
  line-height: 24px;
  word-spacing: 1px;
  font-size: 14px;
  font-family: inherit !important;
  margin-bottom: 15px;
  color: #4c4c4c;
}
// h3 {
//   margin-bottom: 10px;
//   font-family: inherit;
//   font-size: 20px;
//   font-weight: 700;
//   color: #181818;
//   padding: 0;
//   line-height: normal;
// }
.center-img {
  height: 82px;
  display: block;
  margin: 0 auto;
}
</style>
