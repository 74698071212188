<template>
  <div>
    <v-content class="faq_wrap">
      <v-container>
        <!-- <v-row>
          <v-col>
            <h1 class="faq_head blue_head">{{spec.heading}}</h1>
          </v-col>
        </v-row> -->

        <!-- <v-row class="justify-center search_box">
          <v-col cols="12">
            <div class="search_box_wrap">
              <v-text-field label="Start typing to find answers...." v-model="search" solo></v-text-field>
              <i class="material-icons">search</i>
            </div>
          </v-col>
        </v-row> -->
        <v-row v-for="(item) in filteredList" v-bind:key="item.sub_topic_id" class="faq_accordion">
          <v-col>
            <!-- <v-row>
              <v-col>
                <h2 class="faq_acc_head blue_head">{{item.heading}}</h2>
              </v-col>
            </v-row> -->
            <v-row class="acc_style_2">
              <v-col>
                <v-expansion-panels>
                  <v-expansion-panel v-for="(item_2) in item.topic" v-bind:key="item_2.topic_id">
                    <v-expansion-panel-header>{{ item_2.title }}</v-expansion-panel-header>
                    <v-expansion-panel-content>{{ item_2.detail }}</v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <p v-if="item.topic.length == 0">No Topics</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
    <v-content class="get-in-touch">
      <v-container>
        <v-row>
          <v-col>
            <h4 class="pink_head heading_border_btm">
              <span>{{spec.contact.heading}}</span>
            </h4>
            <h2 class="blue_head">{{spec.contact.sub_heading}}</h2>
            <p>{{ spec.contact.description }}</p>
            <a
              v-if="spec.contact.button_2"
              :href="spec.contact.link_2"
              class="default_btn_2 default_btn"
            >{{ spec.contact.button_2 }}</a>
            <a
              v-if="spec.contact.button_1"
              :href="spec.contact.link_1"
              class="default_btn"
            >{{ spec.contact.button_1 }}</a>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </div>
</template>

<script>
export default {
  props: ['spec'],
  data() {
    return {
      search: '',
      // topicList: this.spec.sub_topics,
      topicList_filtered: [],
      filteredList: []
    };
  },
  computed: {
    // filteredList() {
    //   this.topicList_filtered.length = 0;
    //   for (let i = 0; i < this.topicList.length; i++) {
    //     const filteredObject = {};
    //     filteredObject['sub_topic_id'] = this.topicList[i].sub_topic_id;
    //     filteredObject['heading'] = this.topicList[i].heading;
    //     filteredObject['topic'] = this.topicList[i].topic.filter((topicData) => {
    //       return topicData.title
    //           .toLowerCase()
    //           .includes(this.search.toLowerCase());
    //     });
    //     this.topicList_filtered.push(filteredObject);
    //   }

    //   return this.topicList_filtered;

    //   // return this.sub_topicList.filter(topic => {
    //   //   return topic.heading.toLowerCase().includes(this.search.toLowerCase())
    //   // })
    // },
  },
  methods: {
    $ready() {
      this.topicList = this.spec.sub_topics;

      this.topicList_filtered.length = 0;
      for (let i = 0; i < this.topicList.length; i++) {
        const filteredObject = {};
        filteredObject['sub_topic_id'] = this.topicList[i].sub_topic_id;
        filteredObject['heading'] = this.topicList[i].heading;
        filteredObject['topic'] = this.topicList[i].topic.filter((topicData) => {
          return topicData.title
              .toLowerCase()
              .includes(this.search.toLowerCase());
        });
        this.topicList_filtered.push(filteredObject);
      }

      this.filteredList = this.topicList_filtered;
    }

  }
};
</script>

<style lang="scss" scoped>
.acc_style_2 .theme--light.v-expansion-panels .v-expansion-panel {
  border: 1px solid #d8d8d8 !important;
  margin-top: 30px;
  padding: 10px;

  .v-expansion-panel-header {
    font-weight: bold;
  }
}
.acc_style_2 .theme--light.v-expansion-panels .v-expansion-panel:first-child {
  margin-top: 0;
}
.acc_style_2 .v-expansion-panel-header__icon {
  background-image: "../../../../../assets/images/accordian-close.svg";
  width: 20px;
  height: 20px;
}
.acc_style_2 .v-expansion-panel--active .v-expansion-panel-header__icon {
    background-image: "../../../../../assets/images/accordian-open.svg";
}
.acc_style_2 .v-expansion-panel-header__icon i {
  display: none;
}
</style>

