<template>
  <div
    class="background"
    justify="center"
    :class="{
      'lg-up': $vuetify.display.lgAndUp,
      md: $vuetify.display.md,
      sm: $vuetify.display.sm,
      xs: $vuetify.display.xs
    }"
  >
    <!-- <h1 class="views-h1"><a name="howItWorks">{{ spec.title }}</a></h1> -->
    <h1 class="views-h1" id="howItWorks">{{ spec.title }}</h1>

    <v-row class="content mx-auto">
      <v-col cols="12" sm="1" md="1">
      </v-col>

      <template v-for="(item, index) in spec.items">
        <v-col v-if="cardVisible(item)" :key="index" cols="12" sm="2" md="2" @click="$onClick($event, item)">
          <div class="card-background mt-10">
            <img class="center-img" :src="item.imageUrl" />
          </div>
          <h4 class="text-center views-h4 mt-10">
            {{ item.message }}
          </h4>
        </v-col>
      </template>

      <v-col cols="12" sm="1" md="1">
      </v-col>

      <v-col cols="12" sm="1" md="1">
      </v-col>

      <v-col cols="12" sm="10" md="10">
        <div class="footer views-p">{{spec.footerText}}</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    spec: { type: Object, required: true },
  },
  data: () => ({}),
  methods: {
    $ready() {},
    cardVisible(item) {
      // if (item.title) {
      //   return true;
      // }
      // return this.$vuetify.breakpoint.mdAndUp;
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 60px;
  text-align: center;
  // font-size: 40px;
  // font-weight: 700;
  // color: #37325f;
}
.background {
  // background-color: #fafafa !important;
  // background-color: #d2dbd8
  background-color: #000000;
  color: #eded4e;

  h4 {
    // font-size: 30px;
    // font-weight: 300;
    color: #eded4e;
  }
}
.content {
  padding: 20px 0px 40px;
}
// .list-number {
//   width: 50px;
//   font-size: 40px;
//   color: #37325f;
//   display: inline-block;
//   vertical-align: middle;
// }
// .list-title {
//   // padding: 8px 0px;
//   font-size: 20px;
//   color: #37325f;
//   display: inline-block;
// }
.lg-up {
  .content {
    max-width: 1025px !important;
  }
}
.md {
  .content {
    max-width: 900px !important;
  }
}
.sm {
  .content {
    max-width: 90vw !important;
  }
}
.xs {
  .content {
    width: 100vw !important;
  }

  p {
    max-width: 360px;
  }
}
.card-background {
  // padding-top: 8px;
  // background-color: #fafafa !important;
  // background-color: transparent !important;
  background-color: #eded4e !important;

  width: 160px;
  height: 160px;

  // background-color: #ffffff;
  // background-color: $base-color;
  // border: 10px solid $highlight-color;
  border-radius: 50%;
  // padding: 60px 10px 28px 10px;
  // text-align: center;
  // cursor: pointer;
  max-width: 340px;
  padding: 40px 0px 0px 0px;
  margin: 0 auto;
}
p {
  line-height: 24px;
  word-spacing: 1px;
  font-size: 14px;
  font-family: inherit !important;
  margin-bottom: 15px;
  color: #4c4c4c;
}
// h3 {
//   margin-bottom: 10px;
//   font-family: inherit;
//   font-size: 20px;
//   font-weight: 700;
//   color: #181818;
//   padding: 0;
//   line-height: normal;
// }
.center-img {
  // height: 82px;
  // height: 50px;
  // display: block;
  // margin: 0 auto;
  // margin-bottom: 18px;

  height: 72px;
  display: block;
  margin: 0 auto;
}
.footer {
  margin-top: 14px;
  text-align: center;
}
</style>
