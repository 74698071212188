<template>
  <div class="box" @click="$onClick" :class="{
      highlighted: spec.highlight,
    }">
    <div class="price">{{spec.value}}</div>
    <div class="name">{{spec.name}}</div>
  </div>
</template>

<script>
export default {
  props: ['spec'],
  data: () => ({}),
  computed: {},
};
</script>

<style lang="scss" scoped>
// $base-color: #5a8350;
// $highlight-color: #fbb163;
$base-color: #3e9fb7;
$highlight-color: #ffdb40;

.box {
  background-color: $base-color;
  border: 10px solid $highlight-color;
  border-radius: 50%;
  padding: 60px 10px 28px 10px;
  text-align: center;
  // cursor: pointer;
  max-width: 240px;
  height: 100%;

  .name {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    white-space: pre-wrap;
    line-height: 1.2;
  }
  .price {
    // margin-top: 4px;
    color: $highlight-color;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.1;
  }

  &.highlighted {
    background-color: $highlight-color;

    .price {
      color: $base-color;
    }
  }
}
</style>
