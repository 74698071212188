<template>
  <div
    class="background"
    justify="center"
    :class="{
      'lg-up': $vuetify.display.lgAndUp,
      md: $vuetify.display.md,
      sm: $vuetify.display.sm,
      xs: $vuetify.display.xs
    }"
  >
    <v-row class="content mx-auto">
      <glib-panels-responsive
        :spec="{
          width: 'matchParent',
          childViews: spec.main
        }"
      />
    </v-row>

        <!--
    <h1 class="views-h1">{{ spec.title }}</h1>

    <div class="text-center" style="padding-bottom: 40px;">
      <glib-panels-responsive
        :spec="{
          width: 'matchParent',
          align: 'center',
          childViews: spec.ctaViews
        }"
      />
    </div>
    -->
  </div>
</template>

<script>
export default {
  props: {
    spec: { type: Object, required: true },
  },
  data: () => ({}),
  methods: {
    $ready() {},
    cardVisible(item) {
      // if (item.title) {
      //   return true;
      // }
      // return this.$vuetify.breakpoint.mdAndUp;
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 40px;
  padding-bottom: 10px;
  text-align: center;
  // font-size: 40px;
  // font-weight: 700;
  // color: #37325f;
}
.background {
  // background-color: #fafafa !important;
  background-color: #eded4e !important;
}
.content {
  padding: 20px 0px;
}
.list-number {
  width: 50px;
  font-size: 40px;
  color: #37325f;
  display: inline-block;
  vertical-align: middle;
}
.list-title {
  // padding: 8px 0px;
  font-size: 20px;
  color: #37325f;
  display: inline-block;
}
.lg-up {
  .content {
    max-width: 1025px !important;
  }
}
.md {
  .content {
    max-width: 900px !important;
  }
}
.sm {
  .content {
    max-width: 90vw !important;
  }
}
.xs {
  .content {
    width: 100vw !important;
  }

  p {
    max-width: 360px;
  }
}
.card-background {
  padding-top: 8px;
  background-color: #fafafa !important;
}
p {
  line-height: 24px;
  word-spacing: 1px;
  font-size: 14px;
  font-family: inherit !important;
  margin-bottom: 15px;
  color: #4c4c4c;
}
// h3 {
//   margin-bottom: 10px;
//   font-family: inherit;
//   font-size: 20px;
//   font-weight: 700;
//   color: #181818;
//   padding: 0;
//   line-height: normal;
// }
.center-img {
  height: 82px;
  display: block;
  margin: 0 auto;
}
</style>
