<template>
  <div
    class="background"
    justify="center"
    :class="{
      'lg-up': $vuetify.display.lgAndUp,
      md: $vuetify.display.md,
      sm: $vuetify.display.sm,
      xs: $vuetify.display.xs
    }"
  >
    <v-row class="content mx-auto">
      <!-- <v-col cols="12" sm="1" md="1">
      </v-col>

      <template v-for="(item, index) in spec.items">
        <v-col v-if="cardVisible(item)" :key="index" cols="12" sm="2" md="2">
          <v-card flat class="card-background">
            <img class="center-img" :src="item.imageUrl" />
            <h4 class="text-center mx-auto views-h4">
              {{ item.message }}
            </h4>
          </v-card>
        </v-col>
      </template> -->

      <v-col cols="12" sm="1" md="2" xs="0" >
      </v-col>

      <v-col cols="12" sm="10" md="8" xs="12" class="text-center mb-16">
        <h2 class="views-h2">{{ spec.title }}</h2>

        <div class="message views-h4">{{spec.message}}</div>
        <div class="message views-h4">{{spec.submessage}}</div>
        <div class="message views-h4">{{spec.motto}}</div>

        <div class="video-container">
          <!-- <iframe src="https://www.dropbox.com/s/b65qatiyj4so1mo/Neowork-Brand_Video-Full.mp4?raw=1" height="480px" width="640px" allowfullscreen></iframe> -->

          <iframe :src="spec.videoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

        <!-- <iframe width="560" height="315" :src="spec.videoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    spec: { type: Object, required: true },
  },
  data: () => ({}),
  methods: {
    $ready() {},
    cardVisible(item) {
      // if (item.title) {
      //   return true;
      // }
      // return this.$vuetify.breakpoint.mdAndUp;
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.video-container {
    overflow: hidden;
    position: relative;
    width:100%;
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

h2 {
  padding-left: 15px;
  padding-right: 15px;
  // padding-top: 60px;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  // font-size: 40px;
  // font-weight: 700;
  // color: #37325f;
  color: #eded4e;
}
.background {
  // background-color: #eded4e;
  background-color: #000000;
}
.content {
  padding: 0px 0px;
}
.list-number {
  width: 50px;
  font-size: 40px;
  color: #37325f;
  display: inline-block;
  vertical-align: middle;
}
.list-title {
  // padding: 8px 0px;
  font-size: 20px;
  color: #37325f;
  display: inline-block;
}
.lg-up {
  .content {
    max-width: 1025px !important;
  }
}
.md {
  .content {
    max-width: 900px !important;
  }
}
.sm {
  .content {
    max-width: 90vw !important;
  }
}
.xs {
  .content {
    width: 100vw !important;
  }

  p {
    max-width: 360px;
  }
}
.card-background {
  padding-top: 8px;
  // background-color: #fafafa !important;
  background-color: transparent !important;
}
p {
  line-height: 24px;
  word-spacing: 1px;
  font-size: 14px;
  font-family: inherit !important;
  margin-bottom: 15px;
  color: #4c4c4c;
}
// .center-img {
//   // height: 82px;
//   height: 50px;
//   display: block;
//   margin: 0 auto;
//   margin-bottom: 18px;
// }
.message {
  margin-top: 14px;
  text-align: center;
  margin-bottom: 10px;
}
</style>
