<template>
  <div
    class="background"
    justify="center"
    :class="{
      'lg-up': $vuetify.display.lgAndUp,
      md: $vuetify.display.md,
      sm: $vuetify.display.sm,
      xs: $vuetify.display.xs
    }"
  >

    <div class="right">
      <v-row class="content mx-auto">
        <v-col cols="12" md="12">
          <glib-panels-responsive
            :spec="{
              width: 'matchParent',
              align: 'center',
              childViews: spec.rightViews
            }"
          />
        </v-col>
      </v-row>
    </div>

    <v-row class="content mx-auto left">
      <v-col cols="12" md="12">
        <glib-panels-responsive
          :spec="{
            width: 'matchParent',
            childViews: spec.leftViews
          }"
        />
      </v-col>
    </v-row>

    <!-- <h1 class="views-h1">{{ spec.title }}</h1>
    <h2 class="views-h1">{{ spec.subtitle }}</h2>

    <v-row class="content mx-auto">
      <template v-for="(item, index) in spec.items">
        <v-col :key="index" cols="12" sm="4" md="4" style="padding: 0px;">
          <v-card flat class="card-background" @click="$onClick($event, item)" v-if="cardVisible(item)">
            <br />
            <div class="box">
              <img class="center-img" :src="item.imageUrl" />
            </div>
            <h3 class="text-center views-h1">{{ item.title }}</h3>
            <p class="text-center views-p" v-for="(subtitle, index) in item.subtitles" :key="index">
              {{ subtitle }}
            </p>
            <br />
          </v-card>
        </v-col>
      </template>
    </v-row> -->
  </div>
</template>

<script>
export default {
  props: {
    spec: { type: Object, required: true },
  },
  data: () => ({}),
  methods: {
    $ready() {},
    cardVisible(item) {
      // if (item.title) {
      //   return true;
      // }
      // return this.$vuetify.breakpoint.mdAndUp;
      return item.title;
    }
  }
};
</script>

<style lang="scss" scoped>

// $base-color: #5a8350;
// $highlight-color: #fbb163;

.box {
  width: 120px;
  height: 120px;

  background-color: #ffffff;
  // background-color: $base-color;
  // border: 10px solid $highlight-color;
  border-radius: 50%;
  // padding: 60px 10px 28px 10px;
  // text-align: center;
  // cursor: pointer;
  max-width: 340px;
  padding: 18px 0px 18px 0px;
  margin: 0 auto;

  // padding: 12px;

    // width: 200px;
    // height: 200px;
  // height: 100%;

  // .name {
  //   color: #ffffff;
  //   font-size: 18px;
  //   font-weight: 500;
  //   white-space: pre-wrap;
  //   line-height: 1.2;
  // }
  // .price {
  //   // margin-top: 4px;
  //   color: $highlight-color;
  //   font-size: 40px;
  //   font-weight: 500;
  //   line-height: 1.1;
  // }

  // &.highlighted {
  //   background-color: $highlight-color;

  //   .price {
  //     color: $base-color;
  //   }
  // }
}
h1 {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 60px;
  text-align: center;
  color: #ffffff;
}
h2 {
  // padding-left: 15px;
  // padding-right: 15px;
  // padding-top: 60px;
  padding-bottom: 10px;
  text-align: center;
  color: #ffffff;
  font-size: 2em;
}
h3 {
  padding-top: 20px !important;
  color: #ffffff !important;
}
p {
  margin-bottom: 0px !important;
  color: #efefef !important;
  line-height: 1.2;
  // font-weight: 100;
}
.background {
  // background-color: #fafafa !important;
  background-color: #eded4e;
}
.content {
  padding: 20px 0px;
}
.lg-up {
  .content {
    max-width: 1025px !important;
  }
}
.md {
  .content {
    max-width: 900px !important;
  }
}
.sm {
  .content {
    max-width: 90vw !important;
  }
}
.xs {
  .content {
    width: 100vw !important;
  }

  p {
    max-width: 360px;
  }
}
.card-background {
  // padding-top: 8px;
  // background-color: #fafafa !important;
  background-color: transparent !important;
  // width: 120px;
}
// p {
//   line-height: 24px;
//   word-spacing: 1px;
//   font-size: 14px;
//   // font-family: inherit !important;
//   margin-bottom: 15px;
//   color: #4c4c4c;
// }
h3 {
  margin-bottom: 10px;
  font-family: inherit;
  font-size: 20px;
  font-weight: 700;
  color: #181818;
  padding: 0;
  line-height: normal;
}
.center-img {
  height: 82px;
  display: block;
  margin: 0 auto;
}

.left {
}

.right {
  background-color: #000000;
  color: #ffffff;
}
</style>
