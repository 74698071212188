/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import { Vue, settings } from 'glib-web/index.js'
import '../../assets/stylesheets/application.css';

// require("channels")
// settings.reactive = true

settings.themes = {
  light: {
    dark: false,
    colors: {
      primary: "#ffdb40"
    }
  }
};

import mixin   from './custom/mixins/styling.js'
Vue.mixin(mixin)

import CommonTemplate from './custom/templates/common.vue'
Vue.component('template-custom-common', CommonTemplate)

import HomeIndexTestimonyTemplate from './custom/templates/home/indexTestimony.vue'
Vue.component('template-home-indexTestimony', HomeIndexTestimonyTemplate)

import HomeIndexJourneyTemplate from './custom/templates/home/indexJourney.vue'
Vue.component('template-home-indexJourney', HomeIndexJourneyTemplate)

import DashboardHeroTemplate from "./custom/templates/dashboard/hero.vue";
Vue.component('template-dashboard-hero', DashboardHeroTemplate);

import FAQDetailIntroTemplate from "./custom/templates/intros/faq_detail.vue";
Vue.component("template-intros-faq_detail", FAQDetailIntroTemplate);

/// New home

import HomeIndexHeroNewTemplate from "./custom/templates/home/new/indexHero.vue";
Vue.component("template-home-indexHeroNew", HomeIndexHeroNewTemplate);

import HomeIndexHowItWorksNewTemplate from './custom/templates/home/new/indexHowItWorks.vue'
Vue.component('template-home-indexHowItWorksNew', HomeIndexHowItWorksNewTemplate)

import HomeIndexWhatItIsNewTemplate from './custom/templates/home/new/indexWhatItIs.vue'
Vue.component('template-home-indexWhatItIsNew', HomeIndexWhatItIsNewTemplate)

import HomeIndexExpertiseNewTemplate from './custom/templates/home/new/indexExpertise.vue'
Vue.component('template-home-indexExpertiseNew', HomeIndexExpertiseNewTemplate)

import HomeIndexGetStartedNewTemplate from './custom/templates/home/new/indexGetStarted.vue'
Vue.component('template-home-indexGetStartedNew', HomeIndexGetStartedNewTemplate)

import NewFooterLayoutTemplate from './custom/templates/layouts/newFooter.vue'
Vue.component('template-layouts-newFooter', NewFooterLayoutTemplate)

